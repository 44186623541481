<template>
  <div class="content">
      <div class="content-item">
        <!-- <van-nav-bar :border='false' @click-left="onClickLeft" left-arrow  class="content-title"/> -->
        <van-image width="60" height="60" :src="require('@/assets/image/hi.png')" class="item-hi"/>
        <div class="item-title">
            <span class="top-title">欢迎来到猩课堂</span>
            <span class="botm-title">免注册，手机验证后快速登录</span>
        </div>
      </div>
      <div class="item-quick">
          <span class="item-quick-title">手机号快捷登录</span>
      </div>
      <!-- <van-form @submit="onSubmit" >
        <van-field
            v-model="username"
            name="手机号"
            label="+86"
            placeholder="13201921739"
            :rules="[{ required: true, message: '请填写手机号' }]"
            class="tel-text"
        /> -->
        <!-- right-icon='cross' -->
        <van-cell-group class="quick-input">
            <van-field v-model="phonenumber" label="+86" placeholder="请输入手机号" :clearable='true'><span class="border-text"></span></van-field>
            <span class="border-text"></span>
        </van-cell-group>
        <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit" color="#23B8FF" class="btn-text" @click="submitLogin">发送验证码</van-button>
        </div>
      <!-- </van-form> -->
      <div>
          <van-icon name="warning" color="#23B8FF" />
          <span class="span-text">阅读并同意 <a href="">《用户服务协议》</a> 和<a href="">《用户隐私政策》</a></span>
      </div>
      <div class="login-mode">其他方式登录</div>
      <div class="mode-item">
          <van-image :src="require('../assets/image/WeChat.png')" class="wechat-img"/>
          <van-image :src="require('../assets/image/an.png')" class="an-img" />
      </div>
  </div>
</template>

<script>

export default {
    data() {
        return {
            username: '',
            password: '',
            phonenumber:''
        }
    },
    methods: {
        onClickLeft() {
            window.history.go(-1);
        },
        onSubmit(values) {
            console.log('submit', values);
        },
        submitLogin() {
            // const temp = {
            //     accountName: this.phonenumber
            // }
            // this.postRequest(API.loginUrl, temp).then((res) => {
            //     setLocalToken(res.data.token) 
            // });
            // this.$router.push({name:'home'})
        }
    }
}
</script>

<style lang="less" scoped>
.content-item {
    height: 306px;
    background: url('../assets/image/lg.png');
    z-index: -1;
    .content-title {
        padding-top: 30px;
    }
    .van-nav-bar {
        background: url('../assets/image/lg.png');
    }
     /deep/ .van-icon-arrow-left:before {
        color: #fff;
    }
    .item-hi {
        margin: 45px 0 0 210px;
    }
    .item-title {
        .top-title {
            display: flex;
            justify-content: space-between;
            font-size: 26px;
            color: #fff;
            margin-left: 20px;
        }
        .botm-title {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            color: rgba(255, 255, 255,60%);
            margin: 20px;
        }
    }
}
.item-quick {
    height: 60px;
    position: relative;
    top: -30px;
    z-index: 20;
    background-color: #fff;
    border-radius: 25px 25px 0 0;
}
.item-quick-title {
    color: #333333;
    font-size: 24px;
    font-weight: 500;
    position: relative;
    top: 30px;
}
.tel-text {
    font-size: 18px;
}
.van-button--round {
    border-radius: 10px;
}
.btn-text {
    font-size: 18px;
}
.span-text {
    font-size: 14px;
    color: #666666;
    margin-left: 5px;
}
.quick-input {
    margin: 0 15px 0 15px;
    border-left: 1px solid #F1F5FA;
    border-right: 1px solid #F1F5FA;
    position: relative;
}
.border-text {
    height: 14px;
    line-height: 14px;
    position: absolute;
    top: 15px;
    left: 49px;
    border: 1px solid #C4C4C4;
}
.login-mode {
    font-size: 10px;
    color: #666666;
    margin-top: 50px;
}
// .wechget {
//     width: 42px;
//     height: 42px;
//     border-radius: 50%;
//     background-color: #68B84B;
// }
.mode-item {
    margin-top: 20px;
    .wechat-img {
        margin-right: 10px;
    }
    .an-img {
        margin-left: 10px;
    }
}
</style>